/* Custom styles for ReactQuill editor toolbar in dark mode */
.dark .ql-toolbar {
  background-color: #89a1c7; /* Background for toolbar in dark mode */
  border-color: #fff; /* Border color for toolbar in dark mode */
}

.dark .ql-container {
  background-color: #374151; /* Background for the editor text area in dark mode */
  color: #fff; /* Text color in the editor */
}

.dark .ql-toolbar button,
.dark .ql-toolbar button svg {
  fill: #ddd !important; /* Change icon color in dark mode */
  color: #ddd !important; /* Change button icon color */
}

.dark .ql-toolbar .ql-picker {
  color: #ddd; /* Color for toolbar dropdowns in dark mode */
}

.dark .ql-toolbar .ql-picker-item {
  color: #ddd; /* Color for items in the dropdown */
}

.dark .ql-toolbar .ql-picker-label {
  color: #ddd; /* Color for labels in the dropdown */
}

.link-nav2 {
  position: relative;
  overflow: hidden;
  padding-bottom: 0.375rem; /* Ajuste ce padding selon le besoin */
  transform: scaleY(0.9);
}

.link-nav2::after {
  content: '' !important;
  position: absolute !important;
  bottom: 0 !important;
  left: 50% !important;
  width: 0% !important;
  height: 3px !important;
  background-color: #F27131 !important; /* Couleur de la barre */
  transition: all 0.3s ease-in-out !important;
}

.link-nav2:hover::after {
  left: 0 !important;
  width: 100% !important;
}

.active2::after {
  left: 0;
  width: 100%;
}

@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');
/* Importation font Montserrat de Google Font*/
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Passion+One:wght@400;700;900&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

/*-       A GARDER --------------------------------------------------------------*/
.app-container {
  display: flex;
  height: 100vh;
}

.app-layout {
  display: flex;
}

.main-container {
  transition: margin-left 0.3s ease;
  flex-grow: 1;
}

.main-container.expanded {
  margin-left: 260px; /* Ajuste cette valeur en fonction de la largeur de ta SideNav étendue */
}

.main-container.collapsed {
  margin-left: 80px; /* Ajuste cette valeur en fonction de la largeur de ta SideNav réduite */
}

.content-container {
  transition: margin-left 0.3s ease;
  width: calc(100% - 260px); /* Largeur par défaut quand SideNav est étendue */
  height: 100vh;
}

.content-container.collapsed {
  margin-left: 50px;
  width: calc(100% - 50px); /* Ajuste la largeur quand SideNav est réduite */
}

.content-container.expanded {
  margin-left: 260px;
}

.main-content {
  padding-top: 100px; /* Ajuste selon le besoin */
  overflow-y: auto;
  height: calc(100vh - 100px); /* Ajuste la hauteur pour tenir compte du padding */
}

/* Ajoutez cette règle pour surcharger le style de survol par défaut */
.menu-list a.is-hoverable:hover,
.menu-list a.is-hoverable:hover .icon {
  background-color: #ffd13a !important;
  /* couleur de fond au survol */
  color: black !important;
  /* couleur du texte au survol */
}




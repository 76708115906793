/* Pour les navigateurs basés sur WebKit (Chrome, Safari) */
.hide-scrollbar::-webkit-scrollbar {
    width: 0;
    height: 0;
}

/* Pour Firefox */
.hide-scrollbar {
    scrollbar-width: none; /* Masquer la scrollbar */
}

/* Pour Internet Explorer et Edge */
.hide-scrollbar {
    -ms-overflow-style: none; /* Masquer la scrollbar */
}

/* Z-index de la flèche */

.zfleche {
    z-index: 1000;
}